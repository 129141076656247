.skill h1 {
  text-align: center;
}
.skill h3 {
  margin: 15px;
}
.skill {
  background-color: rgb(0, 0, 0);
  width: 700px;
  height: 400px;
  margin: 60px auto;
  color: #fff;
  padding: 20px;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px;
  border-radius: 30px;
}
.bar {
  background-color: #353b48;
  display: block;
  height: 20px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0px 14px 28px rgba(0, 0, 0, 0.12), 0 10px 10px rgba(0, 0, 0, 0.2);
  transition: all 0.3s cubic-bezier(0.25, 0.8.25, 1);
}
.bar:hover {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}
.bar span {
  height: 20px;
  float: left;
  background: linear-gradient(135deg, #e1d7dd 0%, rgb(162, 160, 160) 100%);
}
.html {
  width: 90%;
  animation: html 3s;
}
.js {
  width: 70%;
  animation: js 3s;
}
.mern {
  width: 40%;
  animation: mern 3s;
}
.react {
  width: 60%;
  animation: react 3s;
}
@keyframes html {
  0% {
    width: 0%;
  }
  90% {
    width: 90%;
  }
}
@keyframes js {
  0% {
    width: 0%;
  }
  90% {
    width: 70%;
  }
}

@keyframes mern {
  0% {
    width: 0%;
  }
  90% {
    width: 40%;
  }
}
@keyframes react {
  0% {
    width: 0%;
  }
  90% {
    width: 60%;
  }
}
