@import url("https://fonts.googleapis.com/css2?family=Merriweather+Sans&display=swap");
* {
  padding: 0;
  margin: 0;
  font-family: "Merriweather Sans", sans-serif;
  box-sizing: border-box;
}
body {
  background-color: white;
}
* {
  list-style: none;
  padding: 0;
  margin: 0;
}
